import React, { useState, useEffect } from "react";
import { useTranslation } from '../context/TranslationContext';

const About = () => {
  const { language, getTranslation } = useTranslation();
  const [translations, setTranslations] = useState({
    title: 'About Eagle Express',
    description: "This app is built as a serverless application running on AWS Lambda, which handles SMS-based communication between drivers, families, and organizers via the Twilio API. The core functionality includes rider-driver matching based on geographic proximity (using Google Maps API for geocoding and distance calculations), with DynamoDB providing caching support for geocoded addresses.\n\nThe application primarily uses Google Sheets as its database through the Google Sheets API, managing multiple spreadsheets for ride requests, volunteer information, and match tracking. It's written in Python and employs various libraries like pandas for data manipulation, numpy for distance calculations, and gspread for Google Sheets interaction. The architecture follows a command-pattern approach where different text commands trigger specific handlers, making it highly extensible for new features while maintaining a simple SMS-based interface for users."
  });

  useEffect(() => {
    const translateContent = async () => {
      try {
        const [translatedTitle, translatedDescription] = await Promise.all([
          getTranslation(translations.title),
          getTranslation(translations.description)
        ]);
  
        setTranslations(prev => ({
          ...prev,
          title: translatedTitle,
          description: translatedDescription
        }));
      } catch (error) {
        console.error('Translation error:', error);
      }
    };
  
    if (language !== 'en') {
      translateContent();
    }
  }, [language, getTranslation]);

  return (
    <div style={{ padding: "2rem", textAlign: "center" }}>
      <div className="text-container">
        <h1>{translations.title}</h1>
        <p style={{ whiteSpace: 'pre-wrap' }}>
          {translations.description}
        </p>
      </div>
    </div>
  );
};

export default About;