import React, { useState, useEffect } from "react";
import { useTranslation } from '../context/TranslationContext';
import "../App.css";

const TermsOfService = () => {
  const { language, getTranslation } = useTranslation();
  const [translations, setTranslations] = useState({
    title: "Terms of Service",
    volunteerEffort: "This is a volunteer effort being organized by Creekside PTA members. This is not a DPS service. Drivers are parent volunteers trying their best to help families who ask for help.",
    questionsSection: "If you have any questions or concerns, please email us. Please do not request or accept a ride if you have questions that need to be answered, and use common sense about what is safe and acceptable for your family. By requesting a ride, you understand that this is a volunteer effort and you agree that no one involved can be held responsible if something unexpected happens during transportation.",
    disclaimer: "Eric Green created this app in response to a community need. It will likely have bugs and mistakes. If you notice any, please email us (screenshots are always helpful). This service is provided \"as is,\" without any express or implied warranties, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. The developers make no guarantees as to the accuracy, reliability, or completeness of the information or functionality provided by this software. Use of this software is at your own risk. The developers shall not be held liable for any damages, losses, or other liabilities arising from the use of or inability to use this software. To stop receiving messages from the app, please email us."
  });

  useEffect(() => {
    const translateContent = async () => {
      try {
        const [
          translatedTitle,
          translatedVolunteerEffort,
          translatedQuestionsSection,
          translatedDisclaimer
        ] = await Promise.all([
          getTranslation(translations.title),
          getTranslation(translations.volunteerEffort),
          getTranslation(translations.questionsSection),
          getTranslation(translations.disclaimer)
        ]);

        setTranslations(prev => ({
          ...prev,
          title: translatedTitle,
          volunteerEffort: translatedVolunteerEffort,
          questionsSection: translatedQuestionsSection,
          disclaimer: translatedDisclaimer
        }));
      } catch (error) {
        console.error('Translation error:', error);
      }
    };

    if (language !== 'en') {
      translateContent();
    }
  }, [language, getTranslation]);

  // Translate the "email us" text separately
  const emailUsText = language === 'es' ? 'envíenos un correo electrónico' : 'email us';

  return (
    <div style={{ padding: "2rem", textAlign: "center" }}>
      <div className="text-container">
        <h1 className="page-title">{translations.title}</h1>
        <p>
          {translations.volunteerEffort}
        </p>
        <p>
          {translations.questionsSection.split('email us').map((part, index, array) => (
            <React.Fragment key={index}>
              {part}
              {index < array.length - 1 && (
                <a href="mailto:epgreen+eagleexpress@gmail.com">{emailUsText}</a>
              )}
            </React.Fragment>
          ))}
        </p>
        <p>
          {translations.disclaimer.split('email us').map((part, index, array) => (
            <React.Fragment key={index}>
              {part}
              {index < array.length - 1 && (
                <a href="mailto:epgreen+eagleexpress@gmail.com">{emailUsText}</a>
              )}
            </React.Fragment>
          ))}
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;