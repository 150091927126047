import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { TranslationProvider } from "./context/TranslationContext";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import TermsOfService from "./pages/TermsOfService";
import HowItWorks from "./pages/HowItWorks";

const App = () => {
  return (
    <TranslationProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
        </Routes>
      </Router>
    </TranslationProvider>
  );
};

export default App;