import React, { useState, useEffect } from "react";
import { useTranslation } from '../context/TranslationContext';
import "../App.css";

const HowItWorks = () => {
  const { language, getTranslation } = useTranslation();
  const [translations, setTranslations] = useState({
    scheduleTitle: "Rotational Schedule",
    busScheduleIntro: "In December, buses 26 (green), 97 (red), 129 (blue/gray), and 71 (yellow) will not run one day every week on a rotating schedule.",
    mondaySchedule: "Mondays (Dec 2, 9, 16): No service for Bus 26 Green and Bus 71 Yellow",
    tuesdaySchedule: "Tuesdays (Dec 3, 10, 17): No service for Bus 97 Red",
    wednesdaySchedule: "Wednesdays (Dec 4, 11, 18): No service for Bus 129 Gray/Blue",
    priorityNote: "Exceptional children, students with IEPs requiring bus service, and children without a regular address (McKinney Vento) will be prioritized for uninterrupted bus service.",
    ridePlanText: "While this plan makes bus disruptions more predictable, it will be challenging for families without transportation or the flexibility to drive their children to and from school. To address this, the PTA is organizing parent volunteers to drive students who lack a way to get to school. This initiative is parent-run with input from the school and is not affiliated with Creekside or DPS.",
    howItWorksTitle: "How Eagle Express Works",
    step1: "Families use a webform (available in English and Spanish) to submit requests for assistance. Requests must include details such as the children's names, address, and desired times (morning, afternoon, or both). Requests must be submitted by 9 AM the day before and renewed weekly.",
    step2: "After the request window closes, the app searches for available volunteer drivers for the next day. Drivers respond with their availability.",
    step3: "The app matches families with nearby volunteer drivers, aiming to minimize travel time and keep siblings together.",
    step4: "Once a match is found, the app notifies riders and drivers in English or Spanish.",
    familyMessages: "Family (Rider) messages:",
    driverMessage: "Driver message:",
    driverConnectNote: "We ask drivers to connect directly to families once they get an assignment. Drivers can get a sample message to introduce themselves to assigned families by replying with \"sample [request code]\". We'll send a message template in English or Spanish, depending on the needs of the family.",
    faqTitle: "Frequently Asked Questions",
    ridersTitle: "Riders",
    // FAQ Questions and Answers...
    // Add more translation keys as needed for FAQ sections
  });

  useEffect(() => {
    const translateContent = async () => {
      try {
        // Create translation promises for each field
        const translationPromises = Object.entries(translations).map(
          async ([key, value]) => [key, await getTranslation(value)]
        );
  
        // Wait for all translations
        const translatedEntries = await Promise.all(translationPromises);
  
        // Update state with all translations
        setTranslations(prev => ({
          ...prev,
          ...Object.fromEntries(translatedEntries)
        }));
      } catch (error) {
        console.error('Translation error:', error);
      }
    };
  
    if (language !== 'en') {
      translateContent();
    }
  }, [language, getTranslation]);

  return (
    <div style={{ padding: "2rem", textAlign: "center" }}>
      <div className="text-container">
        <h1 className="page-title">{translations.scheduleTitle}</h1>

        <section className="bus-schedule">
          <p>{translations.busScheduleIntro}</p>
          <ul className="schedule-list">
            <li><b>{translations.mondaySchedule}</b></li>
            <li><b>{translations.tuesdaySchedule}</b></li>
            <li><b>{translations.wednesdaySchedule}</b></li>
          </ul>
          <p>{translations.priorityNote}</p>
        </section>

        <section className="ride-plan">
          <p>{translations.ridePlanText}</p>
        </section>

        <h1 className="page-title">{translations.howItWorksTitle}</h1>

        <section className="how-it-works">
          <ol className="how-it-works-list">
            <li>{translations.step1}</li>
            <li>{translations.step2}</li>
            <li>{translations.step3}</li>
            <li>
              {translations.step4}
              <ol type="a">
                <li>
                  {translations.familyMessages}
                  <div className="image-container">
                    <img src="/rider-english.png" alt="Example message in English" className="example-image" />
                    <img src="/rider-spanish.png" alt="Example message in Spanish" className="example-image" />
                  </div>
                </li>
                <li>
                  {translations.driverMessage}
                  <div className="image-container">
                    <img src="/driver.png" alt="Example message in English" className="example-image" />
                  </div>
                  <br />
                  {translations.driverConnectNote}
                  <div className="image-container">
                    <img src="/driver-sample.png" alt="Sample message template for driver to send to Spanish speaking family" className="example-image" />
                  </div>
                </li>
              </ol>
            </li>
          </ol>
        </section>

        {/* FAQ Section */}
        <section className="faq-section">
          <h2>Frequently Asked Questions</h2>
          <h3>Riders</h3>
          <p><b>Q. How do I request a ride?</b><br />
          A. Please see emails from Dr. Creamer to access the request link or <a href="mailto:epgreen+eagleexpress@gmail.com">email us</a> with your child's name, grade, and teacher. We are not posting the link publicly for security.</p>


          <p><b>Q. Can anyone register to drive?</b><br />
          A. No, only self-identified parents or guardians of current Creekside students can register. We do not verify driver qualifications, and there is no mechanism in place to background check drivers.</p>


          <p><b>Q. Can anyone request a ride?</b><br />
          A. No, only parents or guardians of current Creekside students can make a request.</p>


          <p><b>Q. Is there a fee?</b><br />
          A. No, this is a free volunteer effort.</p>


          <p><b>Q. Will cars have booster seats and car seats?</b><br />
          A. It depends on the driver. If your child requires a special seat, please ask the driver if they have one or if you can provide your own. If in doubt, please decline the ride. DPS has said they will not count tardies and absences related to the rotational schedule.</p>


          <p><b>Q. Does a parent have to be present at pick-up and drop-off?</b><br />
          A. We strongly recommend that an adult be present for every pick-up and drop-off. For afternoon drop-off, we advise volunteer drivers to follow the bus practice that an adult must be present to drop off a child in K-2nd grade. If an adult is not present to receive a young rider, the volunteer is asked to return to the school with the child after dropping off other students in the vehicle.</p>


          <p><b>Q. What if my child is not ready when the driver arrives in the morning?</b><br />
          A. Your child must be ready by the pickup time since the volunteer might not be able to wait. Please coordinate directly with the driver.</p>


          <p><b>Q. What if I don't speak the same language as the driver?</b><br />
          A. We recommend using Google Translate or a similar app/website. Communicating with the driver via SMS ahead of the scheduled ride will reduce the need for live translation.</p>


          <p><b>Q. What if I need to cancel after I've been matched?</b><br />
          A. If you need to cancel the ride, please text the volunteer driver directly using the information provided. Please reference the request code if possible.</p>


          <p><b>Q. What if a driver cancels the ride?</b><br />
          A. Please <a href="mailto:epgreen+eagleexpress@gmail.com">email us</a> to see if we can find alternate arrangements. Please reference the request code if possible.</p>
       
       <h3>Parent Volunteers</h3>


       <p><b>Q. How do I volunteer to drive?</b><br />
          A. Please see emails from Dr. Creamer to access the volunteer registration link or <a href="mailto:epgreen+eagleexpress@gmail.com">email us</a> with your name and your child's name, grade, and teacher. We are not posting the link publicly for security.</p>


       <p><b>Q. What if I need to cancel after I've been matched?</b><br />
          A. If you need to cancel after 9pm for a morning ride or after 12pm for an afternoon ride, please text the parent directly and let them know. Also, please send an <a href="mailto:epgreen+eagleexpress@gmail.com">email us</a> with the ride details, including the Request Code. If canceling with more notice, please <a href="mailto:epgreen+eagleexpress@gmail.com">email us</a> first to see if another match can be made. If you do not receive confirmation by 9PM/12PM  that another match has been made or that the ride has been cancelled, please cancel directly with the parent via SMS.</p>
       
      
        </section>

      </div>
    </div>
  );
};

export default HowItWorks;