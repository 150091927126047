// src/utils/translator.js
import { getFromCache, saveToCache } from './translationCache';

const rateLimiter = {
  queue: [],
  maxRequests: 10,
  pending: 0,
  interval: 1000,
  processing: false,

  async addToQueue(translationRequest) {
    return new Promise((resolve, reject) => {
      this.queue.push({ request: translationRequest, resolve, reject });
      this.processQueue();
    });
  },

  async processQueue() {
    if (this.processing) return;
    this.processing = true;

    while (this.queue.length > 0) {
      if (this.pending >= this.maxRequests) {
        await new Promise(resolve => setTimeout(resolve, this.interval));
        this.pending = 0;
      }

      const { request, resolve, reject } = this.queue.shift();
      this.pending++;

      try {
        const result = await request();
        resolve(result);
      } catch (error) {
        reject(error);
      }
    }

    this.processing = false;
  }
};

export async function translateText(text, targetLanguage) {
  if (targetLanguage === 'en') return text;
  
  const cachedTranslation = getFromCache(text, targetLanguage);
  if (cachedTranslation) return cachedTranslation;

  const translationRequest = async () => {
    try {
      const response = await fetch(
        `https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            q: text,
            target: targetLanguage,
            source: 'en'
          })
        }
      );

      if (!response.ok) throw new Error(`Translation failed: ${response.status}`);

      const data = await response.json();
      const translation = data.data.translations[0].translatedText;
      
      saveToCache(text, targetLanguage, translation);
      return translation;
    } catch (error) {
      console.error('Translation error:', error);
      return text;
    }
  };

  return rateLimiter.addToQueue(translationRequest);
}