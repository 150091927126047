import React, { createContext, useState, useContext } from 'react';
import { translateText } from '../utils/translator';
import { saveToCache, getFromCache } from '../utils/translationCache';

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');
  
  const getTranslation = async (text) => {
    console.log('getTranslation called with:', { text, language });
    
    // Return original text if language is English
    if (language === 'en') {
      console.log('Language is English, returning original text');
      return text;
    }
    
    // Check local storage cache first
    const cachedTranslation = getFromCache(text, language);
    console.log('Cache check result:', { cachedTranslation });
    
    if (cachedTranslation) {
      console.log('Returning cached translation');
      return cachedTranslation;
    }

    // If not in cache, translate and cache
    try {
      console.log('No cache found, calling translation API...');
      const translated = await translateText(text, language);
      console.log('Translation API response:', { translated });
      
      console.log('Saving to cache...');
      saveToCache(text, language, translated);
      
      return translated;
    } catch (error) {
      console.error('Translation error:', { error, text, language });
      return text;
    }
  };

  // Add logging to setLanguage
  const handleSetLanguage = (newLanguage) => {
    console.log('Language changing from', language, 'to', newLanguage);
    setLanguage(newLanguage);
  };

  return (
    <TranslationContext.Provider value={{ 
      language, 
      setLanguage: handleSetLanguage, 
      getTranslation 
    }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  const context = useContext(TranslationContext);
  if (!context) {
    console.error('useTranslation must be used within a TranslationProvider');
  }
  return context;
};