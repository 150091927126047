import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "../context/TranslationContext";
import "./Navbar.css";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for toggling the menu
  const { language, setLanguage } = useTranslation();

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    setIsMenuOpen(false); // Close menu after language selection
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev); // Toggle the menu open/close state
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <img src="/eagle-express.png" alt="Eagle Express Logo" />
        <span>Eagle Express</span>
      </div>
      <button
        className="hamburger"
        onClick={toggleMenu}
        aria-label="Toggle menu"
      >
        ☰
      </button>
      <ul className={`nav-links ${isMenuOpen ? "active" : ""}`}>
        <li>
          <Link to="/" onClick={() => setIsMenuOpen(false)}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/how-it-works" onClick={() => setIsMenuOpen(false)}>
            How It Works
          </Link>
        </li>
        <li>
          <Link to="/about" onClick={() => setIsMenuOpen(false)}>
            About
          </Link>
        </li>
        <li>
          <Link to="/terms-of-service" onClick={() => setIsMenuOpen(false)}>
            Terms of Service
          </Link>
        </li>
        <li>
          <select
            value={language}
            onChange={handleLanguageChange}
            className="language-selector"
          >
            <option value="en">English</option>
            <option value="es">Español</option>
          </select>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;