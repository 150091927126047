import React, { useState, useEffect } from "react";
import { useTranslation } from '../context/TranslationContext';
import "./Home.css";
import './../App.css';

const Home = () => {
  const { language, getTranslation } = useTranslation();
  const [translations, setTranslations] = useState({
    title: 'Welcome to Eagle Express',
    subtitle: 'A grassroots effort to arrange rides for Creekside Eagles',
    description: 'Eagle Express was created in response to the severe bus driver shortage impacting Durham Public Schools, which has placed immense strain on school staff and families. At Creekside Elementary, over 350 students rely on buses that were late or did not run 2 out of every 3 days through November. In December, DPS will drop service on many bus routes to 4 days per week, requiring families to make their own arrangements one day each week. These outages aim to spread the burden across the district, but they still leave many families struggling to find reliable transportation. Eagle Express connects Creekside students with volunteer parent drivers to ensure they can get to and from school safely and on time during this crisis.'
  });

  useEffect(() => {
    const translateContent = async () => {
      try {
        const [translatedTitle, translatedSubtitle, translatedDescription] = await Promise.all([
          getTranslation(translations.title),
          getTranslation(translations.subtitle),
          getTranslation(translations.description)
        ]);
  
        setTranslations(prev => ({
          ...prev,
          title: translatedTitle,
          subtitle: translatedSubtitle,
          description: translatedDescription
        }));
      } catch (error) {
        console.error('Translation error:', error);
      }
    };
  
    if (language !== 'en') {
      translateContent();
    }
  }, [language, getTranslation]);

  return (
    <div className="home">
      <h1>{translations.title}</h1>
      <p>{translations.subtitle}</p>
      <img src="/eagle-express.png" alt="Eagle Express Logo" className="home-logo" />
      <div className="text-container">
        <p>{translations.description}</p>
      </div>
    </div>
  );
};

export default Home;