// src/utils/translationCache.js
const CACHE_VERSION = 'v1';
const CACHE_EXPIRY = 30 * 24 * 60 * 60 * 1000; // 30 days

export const clearCache = () => {
  try {
    localStorage.removeItem('translationCache');
    sessionStorage.clear();
    console.log('Translation cache cleared');
  } catch (error) {
    console.error('Error clearing cache:', error);
  }
};

export const saveToCache = (text, language, translation) => {
  try {
    const cache = JSON.parse(localStorage.getItem('translationCache') || '{}');
    
    if (!cache.version || cache.version !== CACHE_VERSION) {
      cache.data = {};
      cache.version = CACHE_VERSION;
    }

    const cacheKey = `${text}_${language}`;
    cache.data = cache.data || {};
    cache.data[cacheKey] = {
      translation,
      timestamp: Date.now(),
      source: text,
      targetLang: language
    };

    // Clean old entries
    const now = Date.now();
    Object.keys(cache.data).forEach(key => {
      if (now - cache.data[key].timestamp > CACHE_EXPIRY) {
        delete cache.data[key];
      }
    });

    localStorage.setItem('translationCache', JSON.stringify(cache));
    sessionStorage.setItem(cacheKey, translation);
  } catch (error) {
    console.error('Cache save error:', error);
  }
};

export const getFromCache = (text, language) => {
  try {
    const cacheKey = `${text}_${language}`;
    const sessionResult = sessionStorage.getItem(cacheKey);
    if (sessionResult) return sessionResult;

    const cache = JSON.parse(localStorage.getItem('translationCache') || '{}');
    
    if (!cache.version || cache.version !== CACHE_VERSION) {
      return null;
    }

    const entry = cache.data?.[cacheKey];
    if (!entry) return null;

    if (Date.now() - entry.timestamp > CACHE_EXPIRY) {
      delete cache.data[cacheKey];
      localStorage.setItem('translationCache', JSON.stringify(cache));
      return null;
    }

    sessionStorage.setItem(cacheKey, entry.translation);
    return entry.translation;
  } catch (error) {
    console.error('Cache read error:', error);
    return null;
  }
};